import { Project } from '../../../services/projects';
import DeleteProject from '../../../components/projects/DeleteProject';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { Helmet } from 'react-helmet';

export default function ProjectSettings({ project }: { project: Project }) {
  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);

  return (
    <>
      <Helmet title={`${project?.name} Settings`} />
      {canUpdateProject && <DeleteProject project={project} />}
    </>
  )
}
