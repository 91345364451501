import { Button, FormikSelectInput, Modal, toast } from '@hyphen/hyphen-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { InferType } from 'yup';

import { ApiError } from '../ApiError';
import { useCreateMemberMutation, useGetMembersQuery, useLazyGetMembersQuery } from '../../services/member';
import { useReplaceTeamMembersMutation } from '../../services/teams';
import { ModalProps } from '../types/modal';
import { CreateMemberData, MemberSelect } from '../types/member';

const addMemberSchema = yup.object().shape({
  members: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup
          .string()
          .email('Invalid email')
          .required(),
      })
    )
    .min(1, 'At least one member is required'),
});

type AddMemberSchema = InferType<typeof addMemberSchema>;

const errorMessages = {
  default: 'Failed to add member',
}

const queryParams = ({ pageNum: 1, pageSize: 50 });

export const AddMemberModal = ({isOpen, onClose, data}: ModalProps) => {
  const { id } = useParams<{ id: any }>();
  const modalRef = useRef<HTMLFormElement>(null);

  const { data: initialMembers, isLoading: initialLoading } = useGetMembersQuery({
    organizationId: data.organization.id,
    ...queryParams
  });
  const [trigger] = useLazyGetMembersQuery();
  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [replaceTeamMembers, { error: replaceError }] = useReplaceTeamMembersMutation();

  const [membersListData, setMembersListData] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (!initialLoading && initialMembers) {
      const membersList = initialMembers.data.map((member) => ({
        value: member.id,
        label: member.email,
      }));
      setMembersListData(membersList);
    }
  }, [initialMembers, initialLoading]);

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    const filteredMembers = await trigger({ organizationId: data.organization.id, email: inputValue }).unwrap();
    return filteredMembers?.data.map((member) => ({
      value: member.id,
      label: member.email,
    })) || [];
  };


  const handleInviteMember = useCallback(async (newMembers: MemberSelect[]) => {
    const responses = await Promise.all(newMembers.map(async (member) => await createMember({
      email: member.label,
      organizationId: data.organization.id,
      firstName: '',
      lastName: '',
    }))) as CreateMemberData[];

    if(responses.length) {
      return responses.map(response => response.data?.id);
    }

    return [];
  }, [createMember, data.organization.id])

  const handleOnSubmit = useCallback(async (values: AddMemberSchema) => {
    let memberIds = values.members
      ?.filter((member: MemberSelect) => !member.__isNew__)
      .map((member: MemberSelect) => member.value) ?? [];

    const newMembers = values.members?.filter((member: MemberSelect) => member.__isNew__);
    const newMemberIds = newMembers?.length ? await handleInviteMember(newMembers): [];

    if (newMemberIds.length) {
      memberIds = [...memberIds, ...newMemberIds];
    }

    const { error } = await replaceTeamMembers({
      orgId: data.organization.id,
      teamId: id,
      data: { memberIds }
    });

    if (!error) {
      toast.success('Member added', { duration: 5000 });
      onClose();
    }
  }, [data.organization.id, handleInviteMember, onClose, replaceTeamMembers, id]);

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} maxWidth="9xl" ref={modalRef}>
      <Modal.Header id="addMemberModal" title="Add Member" onDismiss={onClose} />
        <Formik
          initialValues={{ members: [] }}
          validationSchema={addMemberSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form noValidate>
              <Modal.Body padding="4xl 4xl 5xl" margin="0 0 4xl" gap="lg">
                <Field
                  label="Members"
                  name="members"
                  id="members"
                  options={loadOptions}
                  component={FormikSelectInput}
                  isMulti
                  isCreatable
                  isAsync
                  cacheOptions
                  error={errors.members}
                  menuPortalTarget={modalRef.current}
                  defaultOptions={membersListData}
                />
                {createError || replaceError ? <ApiError error={createError || replaceError} customMessages={errorMessages} /> : null}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
    </Modal>
  )
}
