import { Box, Card } from '@hyphen/hyphen-components';
import { ParentSize } from '@visx/responsive';
import BarChart from '../BarChart';
import { VerticalBarChart } from '../VerticalBarChart';

export const LinkDetailStats = ({ codeStatsData, referralsData }: { codeStatsData: any; referralsData: any }) => {
  const EmptyData = () => (
    <Box margin="2xl 0 0 0" color="disabled" fontSize="sm">
      No data
    </Box>
  );

  return (
    <>
      <Box gap="xl" direction={{ base: 'column', tablet: 'row' }}>
        <Card>
          <Card.Section>
            <Box fontSize="sm">Total Clicks</Box>
            <Box fontWeight="bold" fontSize="2xl">
              {codeStatsData?.clicks.total.toLocaleString()}
            </Box>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <Box fontSize="sm">Unique Clicks</Box>
            <Box fontWeight="bold" fontSize="2xl">
              {codeStatsData?.clicks.unique.toLocaleString()}
            </Box>
          </Card.Section>
        </Card>
      </Box>

      <Box>
        <Card>
          <Card.Section title="Clicks Over Time">
            <div style={{ width: '100%', minHeight: '500px', height: '500px' }}>
              {codeStatsData?.clicks.byDay && (
                <ParentSize>
                  {({ width, height }) => (
                    <BarChart width={width} height={height} data={codeStatsData.clicks.byDay} />
                  )}
                </ParentSize>
              )}
            </div>
          </Card.Section>
        </Card>
      </Box>

      <Box gap={{ base: '2xl', tablet: '4xl' }} direction={{ base: 'column', tablet: 'row' }}>
        <Card>
          <Card.Section title="Referral URLs">
            {referralsData.length > 0 ? (
              <VerticalBarChart margin="2xl 0 0 0" tableData={referralsData} />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
        <Card>
          <Card.Section title="User Locations">
            {codeStatsData?.locations.length > 0 ? (
              <VerticalBarChart
                margin="2xl 0 0 0"
                tableData={codeStatsData.locations.map((l: any) => ({ ...l, name: l.country }))}
              />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
      </Box>

      <Box gap={{ base: '2xl', tablet: '4xl' }} direction={{ base: 'column', tablet: 'row' }}>
        <Card>
          <Card.Section title="Browser Usage">
            {codeStatsData?.browsers.length > 0 ? (
              <VerticalBarChart margin="2xl 0 0 0" tableData={codeStatsData.browsers} />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
        <Card>
          <Card.Section title="Device Usage">
            {codeStatsData?.devices.length > 0 ? (
              <VerticalBarChart margin="2xl 0 0 0" tableData={codeStatsData.devices} />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
      </Box>
    </>
  );
};
