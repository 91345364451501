import { Box, Button, Card, Heading } from '@hyphen/hyphen-components';
import { Project } from '../../../services/projects';
import { Link } from 'react-router-dom';
import { ListItem } from '../../../components/ListItem';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';

export default function ProjectOverview({ project }: { project: Project }) {
  const { apps = [] } = project;
  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);

  return (
    <>
      <Box alignItems="flex-start" gap="xl">
        <Heading as="h2" size="sm">
          Apps ({apps.length})
        </Heading>
        {canUpdateProject && (
          <Button
            // @ts-ignore
            as={Link}
            to={`/${project.organization.id}/app/create?projectId=${project.id}`}
            variant="primary"
            size="sm"
          >
            Create App
          </Button>
        )}
        <Box
          width="100"
          display="grid"
          gap={{ base: 'xl', desktop: '2xl' }}
          direction="row"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          }}
        >
          {apps.map((app) => (
            <Card key={app.id}>
              <ListItem
                title={{
                  label: app.name,
                  link: `/${project.organization.id}/${project.alternateId}/app/${app.alternateId}`,
                }}
                // subtitle={<ClickToCopy text={app.alternateId} />}
              />
            </Card>
          ))}
        </Box>
      </Box>
    </>
  );
}
