import { api } from './api';
import { AppParams } from './apps';
import { ListResponse, QueryParams } from './types';

export interface Env {
  id: string;
  size: string;
  countVariables: number;
  version: number;
  published: string;
  projectEnvironment?: {
    id: string;
    alternateId: string;
    name: string;
  };
}

export interface EnvParams {
  appId: string;
  organizationId: string;
  environmentId: string;
}

export const envApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnvs: builder.query<ListResponse<Env>, AppParams & Pick<QueryParams, 'pageSize' | 'pageNum'>>({
      query: ({ pageSize, pageNum, organizationId, appId }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        return {
          url: `api/organizations/${organizationId}/apps/${appId}/envs?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'Env', id: 'ENVS-LIST' }] : []),
    }),
    getAppEnvVersions: builder.query<ListResponse<Env>, AppParams & Pick<QueryParams, 'pageSize' | 'pageNum'>>({
      query: ({ pageSize, pageNum, organizationId, appId }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        return {
          url: `api/organizations/${organizationId}/apps/${appId}/env/versions?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'Env', id: 'ENVS-LIST' }] : []),
    }),
    getEnvVersions: builder.query<ListResponse<Env>, EnvParams & Pick<QueryParams, 'pageSize' | 'pageNum'>>({
      query: ({ pageSize, pageNum, organizationId, appId, environmentId }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        return {
          url: `api/organizations/${organizationId}/apps/${appId}/environments/${environmentId}/env/versions?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'Env', id: 'ENVS-LIST' }] : []),
    }),
  }),
});

export const { useGetEnvsQuery, useGetAppEnvVersionsQuery, useGetEnvVersionsQuery } = envApi;
