import { Box, Button, Card, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import { InferType } from 'yup';
import * as yup from 'yup';
import { ApiError } from '../ApiError';
import { App, useUpdateAppMutation } from '../../services/apps';

const editAppFormSchema = yup.object().shape({
  name: yup.string().required('App name is required'),
});

export type EditAppSchema = InferType<typeof editAppFormSchema>;

interface EditAppProps {
  app: App;
}

export const EditAppForm = ({ app }: EditAppProps) => {
  const initialValues = {
    name: app.name,
  };

  const [updateApp, { error }] = useUpdateAppMutation();

  const handleSubmitEditApp = async (values: EditAppSchema) => {
    const { error, data } = await updateApp({
      organizationId: app.organization.id,
      appId: app.id,
      body: { name: values.name },
    });

    if (!error && data) {
      toast.success('Name updated');
    }
  };

  return (
    <Card>
      <Card.Section gap="xl">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={editAppFormSchema}
          onSubmit={handleSubmitEditApp}
        >
          {({ errors, isSubmitting }) => (
            <Form noValidate>
              <Box gap="2xl">
                <Field
                  type="text"
                  label="App Name"
                  name="name"
                  id="name"
                  autoComplete="off"
                  component={FormikTextInput}
                  error={errors.name}
                  isRequired
                  isDisabled={isSubmitting}
                />
                {error && <ApiError error={error} title="Error updating app name" />}
                <Box display="block">
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Card.Section>
    </Card>
  );
};
