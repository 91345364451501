import { Box, Card, Pagination, Spinner } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import { DomainItem } from '../../components/domains/DomainItem';
import { useGetDomainsQuery } from '../../services/zelda/domains';
import { useEffect, useState } from 'react';
import { Domain } from '../../types/zelda';
import { useOrganization } from '../../providers/OrganizationProvider';

const PAGE_SIZE = 5;

export default function Domains() {
  const [pageNum, setPageNumber] = useState<number>(1);
  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const [pollingInterval, setPollingInterval] = useState(3000);
  const {
    data: domains,
    error,
    isLoading,
  } = useGetDomainsQuery({ pageSize: PAGE_SIZE, pageNum, organizationId }, { pollingInterval });

  useEffect(() => {
    if (domains && domains.data.length && domains.data.some((domain) => domain.sslStatus === 'generating')) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [setPollingInterval, domains]);

  const domainsData = domains?.data || [];

  useEffect(() => {
    if (!isLoading && !error && domains) {
      setPageNumber(domains.pageNum);
    }
  }, [domains, error, isLoading]);

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Domains</title>
      </Helmet>
      <PageHeader
        title="Domains"
        description="Add a domain to replace &ldquo;h4n.link&rdquo; with your chosen vanity URL to create short links that include your brand"
      />
      <Link
        to="add"
        className="hyphen-components__variables__button hyphen-components__variables__form-control Button-module_button__18Bed Button-module_size-md__BkuGu Button-module_primary__st6yY"
      >
        Add a Domain
      </Link>
      {isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner size="md" />
          Loading...
        </Box>
      ) : (
        <>
          <Box width="100" gap="lg">
            <Box
              width="100"
              direction="row"
              gap="2xl"
              padding="0 2xl"
              alignItems="flex-end"
              fontSize="sm"
              color="secondary"
            >
              <Box flex="auto">Domain</Box>
              <Box width="5xl">DNS</Box>
              <Box width="5xl">SSL</Box>
              <Box width="36px" />
            </Box>
            <Card>
              {domainsData.length === 0 && <Box padding="3xl">No domains added yet</Box>}
              {domainsData.map((domain: Domain) => (
                <DomainItem
                  key={domain.id}
                  domain={domain}
                  dateAdded={domain.createdAt}
                  organization={organization}
                />
              ))}
              {domainsData.length > 0 && (domains?.total ?? 0) > PAGE_SIZE && (
                <Pagination
                  activePage={pageNum}
                  itemsPerPage={PAGE_SIZE}
                  onChange={setPageNumber}
                  totalItemsCount={domains?.total ?? 0}
                  isCompact
                  arePagesVisible
                  numberOfPagesDisplayed={3}
                />
              )}
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
}
