import { Box, Button, Popover, useOpenClose } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ConfirmModal } from '../common/ConfirmModal';
import { CustomMessages } from '../ApiError';

const QrCodeItem = ({
  qrCode,
  isDeleting,
  handleQrCodeDelete,
  error,
  deleteQrCodeMessage
}: {
  qrCode: any;
  isDeleting: boolean;
  handleQrCodeDelete: (param: string) => void;
  error: any,
  deleteQrCodeMessage: CustomMessages

}) => {
  const { isOpen: isQrDeleteOpen, handleOpen: openQrDelete, handleClose: closeQrDelete } = useOpenClose();
  const { isOpen: isMenuOpen, handleOpen: openMenu, handleClose: closeMenu } = useOpenClose();
  return (
    <Box key={qrCode.id} width="158px" borderColor="default" borderWidth="sm" radius="sm" overflow="hidden">
      <ConfirmModal
        message={`Are you sure you want to delete the QR code?`}
        confirmButtonLabel="Delete"
        isOpen={isQrDeleteOpen}
        onClose={closeQrDelete}
        onConfirm={() => handleQrCodeDelete(qrCode.id)}
        isLoading={isDeleting}
        title={`Delete QR code`}
        description="Once deleted, it will be gone forever. Please be certain."
        error={error}
        customErrorMessages={deleteQrCodeMessage}
      />
      <Link to={qrCode.qrLink} target="_blank" rel="noopener noreferrer">
        <img src={qrCode.qrLink} alt="QR Code" width="100%" />
      </Link>
      <Box direction="row" justifyContent="space-between" alignItems="center" gap="xs" padding="sm md lg md">
        <Box fontSize="sm" color="secondary" className="text-overflow-clamp" title={qrCode.title || ''}>
          {qrCode.title || '-'}
        </Box>
        <Popover
          content={
            <Box gap="sm">
              <Link
                to={qrCode.qrLink}
                target="_blank"
                rel="noopener noreferrer"
                className="hyphen-components__variables__form-control Button-module_button__18Bed Button-module_size-sm__6Xrjw Button-module_secondary__j-3rj"
              >
                Download
              </Link>
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  openQrDelete();
                  closeMenu();
                }}
              >
                Delete
              </Button>
            </Box>
          }
          isOpen={isMenuOpen}
          placement="bottom-end"
          onClickOutside={closeMenu}
        >
          <Button variant="tertiary" onClick={openMenu} size="sm" iconPrefix="dots" aria-label="qr code options" />
        </Popover>
      </Box>
    </Box>
  );
};

export default QrCodeItem;
