import { Outlet } from 'react-router-dom';
import { EnvsList } from '../../../components/env/EnvList';
import { EventList } from '../../../components/env/EventList';
import { App } from '../../../services/apps';

export default function AppOverview({ app }: { app: App }) {
  return (
    <>
      <EnvsList app={app} />
      {/*<FirewallTable />*/}

      <EventList appId={app.id} title="Event Log" />
      <Outlet />
    </>
  );
}
