import {
  Box,
  Button,
  Card,
  CheckboxInput,
  Pagination,
  Popover,
  Spinner,
  TextInput,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import { ShortLink } from '../../components/link/ShortLinkItem';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useGetCodesQuery, useGetTagsQuery } from '../../services/zelda/codes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

const PAGE_SIZE = 50;

export default function ShortLinkDashboard() {
  const { isOpen: isTagFilterOpen, handleClose: closeTagFilter, handleOpen: openTagFilter } = useOpenClose();

  const [pageNum, setPageNumber] = useState<number>(1);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagsData, setTagsData] = useState<{ value: string; isChecked: boolean }[]>([]);
  const [searchCodeTitle, setSearchCodeTitle] = useState<string>('');
  const [debounceSearchTerm, setDebounceSearchTerm] = useState<string>('');
  const { organization } = useOrganization();
  const organizationId = organization?.id;

  const ability = useOrganizationAbilityContext();
  const canCreateShortLink = ability.can('create', EntityNames.LinkCode);

  const {
    data: codes,
    error,
    isLoading,
  } = useGetCodesQuery({
    pageSize: PAGE_SIZE,
    pageNum,
    tags: selectedTags,
    search: debounceSearchTerm,
    organizationId,
  });

  const { data: orgTags, error: orgTagsError, isLoading: isOrgTagsQueryLoading } = useGetTagsQuery(organizationId);

  const codesData = codes?.data || [];

  useEffect(() => {
    if (!isLoading && !error && codes) {
      setPageNumber(codes.pageNum);
    }
  }, [codes, error, isLoading]);

  useEffect(() => {
    if (!isOrgTagsQueryLoading && !orgTagsError && orgTags && orgTags.length > 0) {
      const transformedTags = orgTags.map((tag) => ({ value: tag, isChecked: false }));
      setTagsData(transformedTags);
    }
  }, [isOrgTagsQueryLoading, orgTags, orgTagsError]);

  const handleOnTagsChange = useCallback(
    (tagChanged: { value: string; isChecked: boolean }) => {
      const updatedTagsData = tagsData.map((tag) => {
        if (tag.value === tagChanged.value) {
          const isSelected = tagChanged.isChecked;
          return { ...tag, isChecked: !isSelected };
        }
        return tag;
      });
      setTagsData(updatedTagsData);
    },
    [tagsData],
  );

  const applyTagsFilter = useCallback(() => {
    const flatSelectedTags = tagsData.filter((item) => item.isChecked).map((item) => item.value);
    setSelectedTags(flatSelectedTags);
  }, [tagsData]);

  const debouncedSetSearchValue = useMemo(() => debounce((value: string) => setDebounceSearchTerm(value), 500), []);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchCodeTitle(value);
    debouncedSetSearchValue(value);
  };

  const tagFilterContent = (
    <Box gap="lg">
      {tagsData.length > 0 && (
        <Box gap="sm" overflow="auto" className="scroll-bar-thin" maxHeight="7xl">
          {tagsData.map((tag) => (
            <CheckboxInput
              id={`tag-${tag.value}`}
              label={tag.value}
              value={tag.value}
              key={tag.value}
              size="sm"
              isChecked={tag.isChecked}
              onChange={() => handleOnTagsChange(tag)}
            />
          ))}
        </Box>
      )}
      <Button variant="primary" size="sm" onClick={applyTagsFilter} disabled={isLoading}>
        Apply
      </Button>
    </Box>
  );

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>Link</title>
      </Helmet>

      <PageHeader title="Link" description="URL shortening service and link management" />
      {canCreateShortLink && (
        <Link to={`/${organizationId}/link/create`}>
          <Button size="md">Create a short link</Button>
        </Link>
      )}
      <Box width="100" gap="lg">
        <Box direction="row" gap="md">
          <Box width="8xl">
            <TextInput
              id="searchInput"
              label="Search"
              hideLabel
              placeholder="Search short links..."
              value={searchCodeTitle}
              onChange={handleSearchInputChange}
              size="sm"
            />
          </Box>
          <Popover
            content={tagFilterContent}
            isOpen={isTagFilterOpen}
            placement="bottom-start"
            offsetFromTarget={4}
            onClickOutside={closeTagFilter}
            hasArrow={false}
            contentContainerProps={{
              padding: 'sm',
              gap: 'xs',
              borderWidth: 'sm',
              borderColor: 'default',
              width: '100',
              maxWidth: '7xl',
            }}
          >
            <Button
              variant="secondary"
              size="sm"
              iconSuffix="caret-sm-down"
              onClick={!isTagFilterOpen ? openTagFilter : closeTagFilter}
            >
              tag
            </Button>
          </Popover>
        </Box>
        {isLoading ? (
          <Box gap="md" direction="row" alignItems="center">
            <Spinner size="md" />
            Loading...
          </Box>
        ) : (
          <>
            <Card>
              {codesData.length === 0 && <Box padding="3xl">No short links created yet</Box>}
              {codesData.map((code) => (
                <ShortLink key={code.id} code={code} orgTags={orgTags} />
              ))}
              {codesData.length > 0 && (codes?.total ?? 0) > PAGE_SIZE && (
                <Pagination
                  activePage={pageNum}
                  itemsPerPage={PAGE_SIZE}
                  onChange={setPageNumber}
                  totalItemsCount={codes?.total ?? 0}
                  isCompact
                  arePagesVisible
                  numberOfPagesDisplayed={3}
                />
              )}
            </Card>
          </>
        )}
      </Box>
    </ScrollableMainLayout>
  );
}
