import { Box, Button, Card } from '@hyphen/hyphen-components';

export const DeleteItemCard = ({ itemLabel = 'item', onDelete }: { itemLabel: string; onDelete: () => void }) => (
  <Card borderColor="danger">
    <Box padding="2xl" alignItems="center" direction="row" gap="2xl">
      <Card.Section padding="0" title={`Delete ${itemLabel}`} flex="auto">
        <Box as="p" flex="auto" fontSize="sm">
          Once deleted, it will be gone forever. Please be certain.
        </Box>
      </Card.Section>
      <Button variant="danger" onClick={onDelete}>
        Delete
      </Button>
    </Box>
  </Card>
);
