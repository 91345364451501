import { ListResponse } from './types';
import { api } from './api';
import { cleanRequest } from '../utils/requestUtils';
import { Member, ReplaceMembers, Team, TeamDetails } from '../types/teams';

export const teamsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTeams: build.query<ListResponse<Team>, string>({
      query: (orgId: string) => ({
        url: `api/organizations/${orgId}/teams`,
        method: 'GET',
      }),
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }) => [
        ...result.data.map(({ id }) => ({ type: 'Teams' as const, id })),
        { type: 'Teams', id: 'LIST' },
      ],
    }),
    getTeam: build.query<TeamDetails, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `api/organizations/${orgId}/teams/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'Teams', id }],
    }),
    getTeamMembers: build.query<ListResponse<Member>, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `api/organizations/${orgId}/teams/${id}/members`,
        method: 'GET',
      }),
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }, error, { id: teamId }) => [
        ...result.data.map(({ id }) => ({ type: 'TeamMembers' as const, id: `${teamId}:${id}` })),
        { type: 'TeamMembers', id: `${teamId}:LIST` },
      ],
    }),
    deleteTeamMember: build.mutation<void, { orgId: string; teamId: string; memberId: string }>({
      query: ({ orgId, teamId, memberId }) => ({
        url: `api/organizations/${orgId}/teams/${teamId}/members/${memberId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { teamId, memberId }) => [
        { type: 'TeamMembers', id: `${teamId}:${memberId}` },
        { type: 'TeamMembers', id: `${teamId}:LIST` },
      ],
    }),
    createTeam: build.mutation<Team, { orgId: string; team: Omit<Team, 'id' | 'memberCount'> }>({
      query: ({ orgId, team }) => ({
        url: `api/organizations/${orgId}/teams`,
        method: 'POST',
        body: team,
      }),
      invalidatesTags: [{ type: 'Teams', id: 'LIST' }],
    }),
    updateTeam: build.mutation<
      Team,
      { orgId: string; id: string; data: Omit<TeamDetails, 'distributionList' | 'id'> }
    >({
      query: ({ orgId, id, data }) => ({
        url: `api/organizations/${orgId}/teams/${id}`,
        method: 'PATCH',
        body: cleanRequest(data),
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Teams', id },
              { type: 'TeamMembers', id: `${id}:LIST` },
            ]
          : [],
    }),
    deleteTeam: build.mutation<void, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `api/organizations/${orgId}/teams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Teams', id }],
    }),
    replaceTeamMembers: build.mutation<void, { orgId: string; teamId: string; data: ReplaceMembers }>({
      query: ({ orgId, teamId, data }) => ({
        url: `api/organizations/${orgId}/teams/${teamId}/members`,
        method: 'PUT',
        body: cleanRequest(data),
      }),
      invalidatesTags: (_result, _error, { teamId }) => [{ type: 'TeamMembers', id: `${teamId}:LIST` }],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetTeamQuery,
  useGetTeamMembersQuery,
  useDeleteTeamMemberMutation,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useReplaceTeamMembersMutation,
} = teamsApi;
