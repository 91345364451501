import { Box, Card } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { useOrganization } from '../../providers/OrganizationProvider';
import OrganizationAvatarUpload from './Settings/OrganizationAvatarUpload';
import { Organization } from '../../services/organization';
import { OrganizationInfoForm } from '../../components/organization/OrganizationInfoForm';

export default function OrgSettings() {
  const { organization = {} as Organization } = useOrganization();

  return (
    <Box width="100" maxWidth="9xl" gap="4xl">
      <Helmet>
        <title>Organization Settings</title>
      </Helmet>
      <PageHeader title="Organization Settings" description="Manage your organization's settings" />
      <Card>
        <Card.Section>
          <OrganizationAvatarUpload organization={organization} />
        </Card.Section>
      </Card>
      <OrganizationInfoForm organization={organization} />

      {/* <Card>
        <Card.Section gap="4xl" padding="4xl" title="Danger Zone">
          <p>Once deleted, it will be gone forever. Please be certain.</p>
          <Button variant="danger">Delete This Organization</Button>
        </Card.Section>
      </Card> */}
    </Box>
  );
}
