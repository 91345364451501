import { Box } from '@hyphen/hyphen-components';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface TabNavProps {
  children: React.ReactNode;
}

interface TabNavLinkProps {
  to: string;
  children: React.ReactNode;
}

export const TabNav: React.FC<TabNavProps> = ({ children }) => (
  <Box
    alignSelf={{ tablet: 'flex-start' }}
    direction="row"
    fontSize="sm"
    background="secondary"
    radius="md"
    padding="sm"
    gap="sm"
  >
    {children}
  </Box>
);

export const TabNavLink: React.FC<TabNavLinkProps> = ({ to, children }) => {
  return (
    <NavLink end to={to} className="display-flex flex-auto tab-nav justify-content-center">
      {children}
    </NavLink>
  );
};
