import { Box, Button, Card, Heading, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { useDeleteTeamMutation } from '../../services/teams';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../common/ConfirmModal';

export default function DeleteTeam({ team, organizationId }: { team: any; organizationId: string }) {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const navigate = useNavigate();

  const [deleteTeam, { isLoading: isDeleting, error }] = useDeleteTeamMutation();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteTeam({ orgId: organizationId, id: team.id });
    if (!error) {
      toast.success('Team deleted');
      closeDeleteModal();
      navigate(`/${organizationId}/teams`);
    }
  }, [deleteTeam, organizationId, team.id, closeDeleteModal, navigate]);

  return (
    <>
      <Card>
        <Card.Section>
          <Box direction="row" alignItems="center" gap="lg">
            <Box flex="auto" gap="sm">
              <Heading as="h4" size="sm">
                Delete Team
              </Heading>
              <Box as="p" color="secondary" fontSize="sm">
                Deleting the team will remove it from the organization. The distribution list associated with the
                team will also be deleted.
              </Box>
            </Box>
            <Button variant="danger" onClick={openDeleteModal}>
              Delete
            </Button>
          </Box>
        </Card.Section>
      </Card>
      <ConfirmModal
        message={`Are you sure you want to delete ${team.name}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${team.name}`}
        error={error}
      />
    </>
  );
}
