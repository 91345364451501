import { cleanRequest } from "../../utils/requestUtils";
import { api } from "../api";
import { ListResponse } from "../types";
import {
  Code,
  CodeStats,
  CreateCodeRequestBody,
  GetCodesQueryParams,
  QRCode,
  QRCodeRequestBody,
  UpdateCodeRequestBody,
} from '../../types/zelda';
import { addQueryParams } from "../../utils/addQueryParams";

export const codeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCode: builder.mutation<Code, CreateCodeRequestBody>({
      query: (codeBody) => ({
        url: 'link/codes',
        method: 'POST',
        body: cleanRequest(codeBody),
      }),
      invalidatesTags: () => [{ type: 'Codes', id: 'LIST' }],
    }),
    getCodes: builder.query<ListResponse<Code>, GetCodesQueryParams>({
      query: ({ pageSize, pageNum, tags, search, organizationId } = {}) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (tags && tags.length) params.append('tags', tags.join(','));
        if (search && search.trim().length) params.append('search', search.trim());
        if (organizationId) params.append('organizationId', organizationId);

        return {
          url: `link/codes?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result?.data
          ? [...result.data.map(({ id }) => ({ type: 'Codes' as const, id })), { type: 'Codes', id: 'LIST' }]
          : [],
    }),
    getCode: builder.query<Code, {id: string, organizationId?: string}>({
      query: ({id, organizationId}) => addQueryParams(`link/codes/${id}`, {organizationId}),
      providesTags: (result) => (result ? [{ type: 'Codes', id: result.id }] : []),
    }),
    getCodeStats: builder.query<CodeStats, {id: string, organizationId?: string}>({
      query: ({id, organizationId}) => addQueryParams(`link/codes/${id}/stats`, {organizationId}),
      providesTags: (result, _error, id) => (result ? [{ type: 'Codes', id: `${id}-STATS-LIST` }] : []),
    }),
    deleteCode: builder.mutation<void, { codeId: string, organizationId?: string }>({
      query: ({ codeId, organizationId }) => ({
        url: addQueryParams(`link/codes/${codeId}`, {organizationId}),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error) => [{ type: 'Codes', id: 'LIST' }],
    }),
    updateCode: builder.mutation<Code, { codeId: string; updateData: UpdateCodeRequestBody }>({
      query: ({ codeId, updateData }) => ({
        url: `link/codes/${codeId}`,
        method: 'PATCH',
        body: cleanRequest(updateData),
      }),
      invalidatesTags: (_result, _error, { codeId }) => [
        { type: 'Codes', id: codeId },
        { type: 'Codes', id: 'TAGS-LIST' },
        { type: 'Codes', id: 'LIST' },
      ],
    }),
    getTags: builder.query<string[], string | undefined>({
      query: (organizationId) => addQueryParams('link/codes/tags', {organizationId}),
      providesTags: (result) => (result ? [{ type: 'Codes', id: 'TAGS-LIST' }] : []),
    }),
    getQrsCode: builder.query<ListResponse<QRCode>, {codeId: string, organizationId?: string}>({
      query: ({codeId, organizationId}) => addQueryParams(`link/codes/${codeId}/qr`, {organizationId}),
      providesTags: (result, _error, id) => (result ? [{ type: 'Codes', id: id.codeId }] : []),
    }),
    createQrCode: builder.mutation<QRCode, { codeId: string, data?: QRCodeRequestBody }>({
      query: ({ codeId, data }) => ({
          url: `link/codes/${codeId}/qr`,
          method: 'POST',
          body: data ? cleanRequest(data) : {},
      }),
      invalidatesTags: (_result, _error, { codeId }) => [
        { type: 'Codes', id: codeId },
      ],
    }),
    deleteQrCode: builder.mutation<void, { codeId: string, qrId: string, organizationId?: string }>({
      query: ({ codeId, qrId, organizationId }) => ({
        url: addQueryParams(`link/codes/${codeId}/qr/${qrId}`, {organizationId}),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { codeId }) => [{ type: 'Codes', id: codeId }],
    })
  }),
});

export const { 
  useCreateCodeMutation, 
  useDeleteCodeMutation, 
  useGetCodesQuery,
  useGetCodeQuery,
  useGetCodeStatsQuery,
  useGetTagsQuery,
  useUpdateCodeMutation,
  useGetQrsCodeQuery,
  useCreateQrCodeMutation,
  useDeleteQrCodeMutation
} = codeApi;
