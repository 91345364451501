import { Box, Button, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCreateQrCodeMutation } from '../../services/zelda/codes';
import { QRCodeRequestBody } from '../../types/zelda';
import { useOrganization } from '../../providers/OrganizationProvider';
import { ApiError } from '../ApiError';

const createQRSchema = yup.object().shape({
  title: yup.string(),
});

const initialValues = {
  title: '',
};

type CreateQRSchema = InferType<typeof createQRSchema>;
type CreateQRFormProps = {
  showQr: (arg0: boolean) => void;
  codeId: string;
};

const customErrorMessages = {
  default: 'Failed to create QR Code'
}

export const CreateQRForm = ({ showQr, codeId }: CreateQRFormProps) => {
  const [createQrsCode, { error }] = useCreateQrCodeMutation();
  const { organization } = useOrganization();

  const handleCreateQR = async (values: CreateQRSchema) => {
    let body: QRCodeRequestBody | undefined;
      if (values.title) body = { title: values.title, organizationId: organization?.id };
      const { error, data } = await createQrsCode({ codeId, data: body });

      if (!error && data) {
        toast.success('QR Code created successfully');
        showQr(false);
      }
  };

  return (
    <Box
      background="secondary"
      padding="4xl"
      radius="md"
      direction={{ base: 'column', tablet: 'row' }}
      gap={{ base: '2xl', tablet: '4xl' }}
    >
      <Formik initialValues={initialValues} validationSchema={createQRSchema} onSubmit={handleCreateQR}>
        {({ errors, isSubmitting }) => (
          <Box width={{ base: '100', desktop: '50' }}>
            <Form noValidate>
              <Box gap="2xl">
                <Field id="title" name="title" label="Title" component={FormikTextInput} error={errors.title} />
                {error ? <ApiError error={error} customMessages={customErrorMessages} /> : null}
                <Box direction="row" gap="sm">
                  <Button variant="primary" isLoading={isSubmitting} type="submit">
                    Create QR Code
                  </Button>
                  <Button variant="tertiary" isDisabled={isSubmitting} onClick={() => showQr(false)}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
