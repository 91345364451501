import { Box, Button, Heading } from '@hyphen/hyphen-components';
import { Guide } from '../guide/Guide';
import { GuideStep } from '../guide/GuideStep';
import { CodeBlock } from '../Codeblock';

const installScriptMacLinux = `sh -c "$(curl -fsSL https://cdn.hyphen.ai/install/install.sh)"`;
const installScriptWindows = `powershell -c "irm https://cdn.hyphen.ai/install/install.ps1 | iex"`;
const loginScript = `hx auth`;
const initScript = `hx init <app-name>`;
const pushScript = `hx push`;

export const EnvCliInstructions = () => {
  return (
    <>
      <Box
        padding="4xl"
        alignItems="center"
        gap="2xl"
        justifyContent="space-between"
        direction="row"
        background="brand-gradient-purple-reverse"
        radius="lg"
      >
        <Box gap="sm">
          <Heading as="h2" size="md" color="white">
            Set up environment secrets
          </Heading>
          <Box as="p" color="white" fontSize="sm">
            ENV stores, secures and controls access to passwords, API keys, tokens and other secrets for your
            applications.
          </Box>
        </Box>
        <Button
          as="a"
          target="_blank"
          href="https://docs.hyphen.ai/docs/env-secrets-management"
          variant="primary"
          iconSuffix="arrow-right"
          style={{ whiteSpace: 'nowrap' }}
        >
          ENV Setup Guide
        </Button>
      </Box>
      <Guide title="ENV Quick Start">
        <GuideStep title="Install the CLI">
          <p>First, download the Hyphen CLI by running the following command in your terminal:</p>
          <Box gap="xs">
            <Box>macOS/Linux</Box>
            <CodeBlock>{installScriptMacLinux}</CodeBlock>
          </Box>
          <Box gap="xs">
            <Box>Windows</Box>
            <CodeBlock>{installScriptWindows}</CodeBlock>
          </Box>
        </GuideStep>
        <GuideStep title="Login">
          <p>
            Log in to your Hyphen account by running the following command. This will open a browser window (or
            copy-and-paste the url in the terminal) where you will be asked to log in
          </p>
          <CodeBlock>{loginScript}</CodeBlock>
        </GuideStep>
        <GuideStep title="Initialize Your Project">
          <p>
            The first time you use Hyphen ENV in an app, you will need to initialize the app in order to create a
            record of the app in your Hyphen organization. This will also initialize the secret encryption key on
            your machine in the <code>.hxkey</code> file.
          </p>
          <p>Go to the directory where your app source code is located, and run:</p>
          <CodeBlock>{initScript}</CodeBlock>
          <p>
            You will receive a confirmation message indicating that the app has been successfully initialized,
            similar to the following:
          </p>
          <TerminalResponse>
            <div>App successfully initialized</div>
            <div>App Name: quick-start-app</div>
            <div>App AlternateId: quick-start-app</div>
            <div>App ID: app_6709694e54fc37367966d0ce</div>
            <div>Organization ID: org_66f30abb67ebc6bb0c5e0af7</div>
          </TerminalResponse>
        </GuideStep>
        <GuideStep title="Your .env files">
          <p>
            Your app folder will now have .env files created for different environments. Add the environment
            appropriate secrets for each.
          </p>
          <ol>
            <li>
              <code>.env</code>: store default secrets here. will apply to all environments
            </li>
            <li>
              <code>.env.development</code>: in the development environment, secrets defined here override secrets in
              .env
            </li>
            <li>
              <code>.env.production</code>: in the production environment, secrets defined here override secrets .env
            </li>
            <li>
              <code>.env.local</code>: in your local development environment, secrets defined here override the
              default secrets .env
            </li>
          </ol>
        </GuideStep>
        <GuideStep title="Encrypt and push your changes to Hyphen">
          <p>
            When you are ready to push your secrets to Hyphen, encrypt your .env files and push to hyphen using the
            following command:
          </p>
          <CodeBlock>{pushScript}</CodeBlock>
          <p>
            For each .env file you've updated, you will receive confirmation that your changes are pushed. For
            example, if the default, local, development, and production env files were changed, the{' '}
            <code>hx push</code> response would look something like this
          </p>
          <TerminalResponse>
            <div>Local environments: default, development, production</div>
            <div>Environments pushed: default, development, production</div>
          </TerminalResponse>
          <p>Notice that env.local is not included because that Hyphen CLI ignores that file and never pushes it.</p>
        </GuideStep>
      </Guide>
    </>
  );
};

const TerminalResponse = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <Box
    gap="2xs"
    background="secondary"
    fontFamily="monospace"
    padding="sm"
    radius="sm"
    fontSize="xs"
    className={className}
  >
    {children}
  </Box>
);
