import { api } from '../api';
import { ListResponse, QueryParams } from '../types';
import { CreateDomainsRequestBody, DNS, Domain, Status as DomainStatus } from '../../types/zelda';
import { addQueryParams } from '../../utils/addQueryParams';

export const domainApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDomains: builder.mutation<ListResponse<Domain>, CreateDomainsRequestBody>({
      query: (domains) => ({
        url: 'link/domains',
        method: 'POST',
        body: domains,
      }),
      invalidatesTags: () => [{ type: 'Domains', id: 'LIST' }],
    }),
    getDomains: builder.query<ListResponse<Domain>, QueryParams>({
      query: ({ pageSize, pageNum, organizationId } = {}) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (organizationId) params.append('organizationId', organizationId);    
            
        return { 
          url: `link/domains?${params.toString()}`,
          method: 'GET' 
        };
      },
      transformResponse(baseQueryReturnValue: ListResponse<Domain>, meta = undefined, arg: QueryParams): ListResponse<Domain> | Promise<ListResponse<Domain>> {
        if(arg.verified){
          baseQueryReturnValue.data = baseQueryReturnValue.data.filter((domain) => domain.status === DomainStatus.Ready);
        }
        return baseQueryReturnValue;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Domains' as const, id })),
              { type: 'Domains', id: 'LIST' },
            ]
          : [{ type: 'Domains', id: 'LIST' }],
    }),
    deleteDomain: builder.mutation<void, { domainId: string; organizationId?: string }>({
      query: ({ domainId, organizationId }) => ({
        url: addQueryParams(`link/domains/${domainId}`, {organizationId}),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error) => [
        { type: 'Domains', id: 'LIST' },
      ],
    }),
    verifySsl: builder.mutation({
      query: ({ domainId, organizationId}) => ({
        url: `link/domains/${domainId}/verify`,
        method: 'POST',
        body: { organizationId }
      }),
      invalidatesTags: () => [{ type: 'Domains', id: 'LIST' }],
    }),
    getDnsConfig: builder.query<DNS, string | undefined>({
      query: (organizationId) => ({
        url: addQueryParams('link/dns-config', {organizationId}),
        method: 'GET',
      }),
    })
  }),
});

export const { useCreateDomainsMutation, useGetDomainsQuery, useDeleteDomainMutation, useVerifySslMutation, useGetDnsConfigQuery } = domainApi;
