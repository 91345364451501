import { Box, Card, Heading, Pagination } from '@hyphen/hyphen-components';
import { AppItem } from './AppItem';
import { App } from '../../services/apps';
import { ListResponse } from '../../services/types';

interface EnvAppsListProps {
  apps: ListResponse<App>;
  pageNum?: number;
  setPageNumber?: (pageNum: number) => void;
  pageSize?: number;
}

export const AppsList = ({ apps, pageNum, pageSize, setPageNumber }: EnvAppsListProps) => {
  const appsData = apps?.data || [];
  return (
    <>
      <Box gap={{ base: '2xl', tablet: '5xl' }}>
        {Object.entries(
          appsData.reduce((acc, app) => {
            const projectName = app.project.name;
            if (!acc[projectName]) {
              acc[projectName] = [];
            }
            acc[projectName].push(app);
            return acc;
          }, {} as Record<string, App[]>),
        )
          .sort(([projectNameA], [projectNameB]) => projectNameA.localeCompare(projectNameB))
          .map(([projectName, projectApps]) => (
            <Box gap="lg" direction={{ base: 'column', desktop: 'row' }} key={projectName}>
              <Box width="7xl" padding="sm 0 0 0">
                <Heading as="h2" size="sm">
                  {projectName}
                </Heading>
              </Box>
              <Box flex="auto" gap="2xl">
                {projectApps.map((app) => (
                  <Card key={app.alternateId}>
                    <AppItem app={app} />
                  </Card>
                ))}
              </Box>
            </Box>
          ))}
      </Box>

      {pageNum && pageSize && setPageNumber && appsData.length > 0 && (apps?.total ?? 0) > pageSize && (
        <Pagination
          activePage={pageNum}
          itemsPerPage={pageSize}
          onChange={setPageNumber}
          totalItemsCount={apps?.total ?? 0}
          isCompact
          arePagesVisible
          numberOfPagesDisplayed={3}
        />
      )}
    </>
  );
};
