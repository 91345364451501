import { Box, Spinner } from '@hyphen/hyphen-components';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { App, useGetAppQuery } from '../../services/apps';
import ClickToCopy from '../../components/common/ClickToCopy';
import AppOverview from './Apps/AppOverview';
import AppSettings from './Apps/AppSettings';
import Error from '../../Error';
import { TabNav, TabNavLink } from '../../components/TabNav';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import EnvEnvironmentDrawer from '../../components/env/EnvEnvironmentDrawer';

export default function AppContainer() {
  const { appId } = useParams<{ appId: any }>();
  const { organization = {} as Organization } = useOrganization();
  const { data: app = {} as App, error, isLoading } = useGetAppQuery({ appId, organizationId: organization.id });
  const ability = useOrganizationAbilityContext();

  const canUpdateApp = !isLoading && ability.can('update', app);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout fullWidth>
      {!error && isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner size="md" />
          Loading...
        </Box>
      ) : (
        <>
          <Helmet title={`${app?.name}`} />
          <PageHeader
            flex="auto"
            eyebrow={<Link to={`/${organization?.id}/${app.project.alternateId}`}>{app.project.name}</Link>}
            title={app?.name}
            description={<ClickToCopy text={app.alternateId} fontSize="xs" />}
          />
          {/* if we add more sections that require tabs, we can move the ability check to target just the settings tab */}
          {canUpdateApp && (
            <TabNav>
              <TabNavLink to="">Overview</TabNavLink>
              <TabNavLink to="settings">Settings</TabNavLink>
            </TabNav>
          )}

          <AppRoutes app={app} />
        </>
      )}
    </ScrollableMainLayout>
  );
}

const AppRoutes = ({ app }: { app: App }) => {
  return (
    <Routes>
      <Route path="/*" element={<AppOverview app={app} />}>
        <Route path=":pevrId" element={<EnvEnvironmentDrawer />} />
      </Route>
      <Route path="settings/*" element={<AppSettings app={app} />} />
    </Routes>
  );
};
