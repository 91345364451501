import { Box, Button, Heading, Modal, useOpenClose } from '@hyphen/hyphen-components';
import { formatDateWithTime, timeAgo } from '../../utils/dateUtils';
import { EventsResponse } from '../../types/events';
import { EventsTableContent } from './EventsTableContent';
import { Link } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import ClickToCopy from '../common/ClickToCopy';
import { EventTypeLabel } from '@hyphen/nucleus/dist/types';
import { INTEGRATION_NAME_MAP } from '../../constants/integrations';
import { ListResponse } from '../../services/types';

interface EventsTableProps {
  events?: ListResponse<EventsResponse, 'Cursor'>;
  setNextPageCursor: (pageCursor: string) => void;
  isLoading: boolean;
  eventsContent: EventsResponse[];
}

function GetSubjectLabel(type: string, event: EventsResponse) {
  const { organization = {} as Organization } = useOrganization();

  switch (type) {
    case 'ORGANIZATION.INTEGRATION-CONNECTION.CREATE':
    case 'ORGANIZATION.INTEGRATION-CONNECTION.DELETE':
      return INTEGRATION_NAME_MAP[event.data.integrationType];
    case 'ORGANIZATION.MEMBER.ADD':
      return event.data.email;
    case 'LINK.DOMAIN.CREATE':
      return event.data.domains[0].domain;
    case 'LINK.DOMAIN.DELETE':
      return event.data.domain;
    case 'APP.DELETE':
      return (
        <>
          {event.data.name || ''} in project{' '}
          <Link to={`/${organization.id}/${event.data.project?.alternateId}`}>{event.data.project?.name || ''}</Link>
        </>
      );
    case 'APP.CREATE':
    case 'APP.UPDATE':
      return (
        <>
          {event.data.project && (
            <>
              <Link to={`/${organization.id}/${event.data.project.alternateId}/app/${event.data.id}`}>
                {event.data.name || ''}
              </Link>{' '}
              in project{' '}
              <Link to={`/${organization.id}/${event.data.project.alternateId}`}>{event.data.project.name}</Link>
            </>
          )}
        </>
      );
    case 'ENV.CREATE':
    case 'ENV.GET':
      return event.data.version ? (
        <>
          v{event.data.version} for {event.data.app}{' '}
          <span className="font-size-xs font-family-monospace">
            [{event.data.environment === '*' ? 'default (all)' : event.data.environment}]
          </span>
        </>
      ) : (
        ''
      );
    case 'PROJECT.CREATE':
      return <Link to={`/${organization.id}/${event.data.alternateId}`}>{event.data.name || ''}</Link>;
    default:
      return event.data.name || '';
  }
}

export const EventsTable = ({
  events,
  setNextPageCursor,
  isLoading,
  eventsContent,
}: EventsTableProps) => {
  const columnConfig = [
    {
      heading: 'Date',
      dataKey: 'timestamp',
      render: (_cell: any, row: any) => (
        <Box direction={{ base: 'column', tablet: 'row' }} gap={{ base: 'xs', tablet: 'lg', desktop: 'xl' }}>
          <Box>{timeAgo(row.timestamp)}</Box>
          <Box color="secondary">{formatDateWithTime(row.timestamp.toString())}</Box>
        </Box>
      ),
    },
    {
      heading: 'Event',
      dataKey: 'type',
      render: (_cell: any, row: any) => (
        <>
          {EventTypeLabel[row.type as keyof typeof EventTypeLabel] || row.type}{' '}
          {row.data && GetSubjectLabel(row.type, row)}
        </>
      ),
    },
    {
      heading: 'User',
      dataKey: 'user',
    },
    {
      heading: 'IP',
      dataKey: 'ipAddress',
    },
    {
      heading: '',
      dataKey: 'references',
      width: 88,
    },
  ];

  const tableData = eventsContent.map((event, index) => ({
    type: event?.type,
    user: event.member?.name
      ? event.member.name
      : event.actor?.name
      ? event.actor.name
      : event.user?.name
      ? event.user.name
      : 'unknown',
    timestamp: event?.timestamp,
    references: event?.references?.length || event?.data?.entity ? <EventDetailModal event={event} /> : '',
    data: event?.data,
    member: event?.member,
    requestId: event?.requestId,
    ipAddress: event?.data?.userIpAddress || '-',
    key: index,
  }));

  return (
    <EventsTableContent
      events={events}
      tableData={tableData}
      columnConfig={columnConfig}
      setNextPageCursor={setNextPageCursor}
      isLoading={isLoading}
    />
  );
};

const EventDetailModal = ({ event }: { event: EventsResponse }) => {
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  const { references, data } = event;

  return (
    <>
      <Modal maxWidth="9xl" isOpen={isOpen} onDismiss={handleClose}>
        <Modal.Body padding="4xl" gap="2xl">
          <Box gap="2xl">
            <Heading as="h1" size="md">
              Event
            </Heading>
            <Box gap="2xs">
              <Box display="block" as="p">
                {event.member?.name || event.user?.name || event.actor?.name || 'unknown'}{' '}
                <span style={{ textTransform: 'lowercase' }}>
                  {EventTypeLabel[event.type as keyof typeof EventTypeLabel] || event.type}
                </span>{' '}
                {event.data && GetSubjectLabel(event.type, event)}
              </Box>
              <Box color="secondary" fontSize="sm" direction="row" gap="sm">
                <Box>{timeAgo(event.timestamp)}</Box>-<Box>{formatDateWithTime(event.timestamp.toString())}</Box>
              </Box>
            </Box>
            <Box gap="sm">
              {data?.entity && (
                <Box direction="row" alignItems="baseline" gap="md" width="100">
                  <Box flex="auto" fontSize="sm">
                    {data.entity.type}
                  </Box>
                  <ClickToCopy key={data.entity.id} text={data.entity.id} />
                </Box>
              )}
              {references.map((reference: any) => {
                const { id: referenceId, type } = reference;

                return (
                  <Box key={referenceId} direction="row" alignItems="baseline" gap="md" width="100">
                    <Box flex="auto" fontSize="sm">
                      {type}
                    </Box>
                    <ClickToCopy key={referenceId} text={referenceId} />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box alignSelf={{ base: 'stretch', tablet: 'flex-end' }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Modal.Body>
      </Modal>
      <Box
        as="button"
        style={{ textDecoration: 'underline' }}
        onClick={handleOpen}
        borderWidth="0"
        padding="0"
        background="transparent"
        color="secondary"
        hover={{ color: 'base' }}
        cursor="pointer"
      >
        details
      </Box>
    </>
  );
};
