import { NavLink } from 'react-router-dom';
import { Box } from '@hyphen/hyphen-components';
import MainNavItem from '../../components/MainNavItem';
import { useOrganization } from '../../providers/OrganizationProvider';

import { ReactNode } from 'react';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

const NavSectionHeader = ({ children }: { children: ReactNode }) => (
  <Box
    color="secondary"
    padding="md"
    fontSize="xs"
    fontFamily="monospace"
    style={{ textTransform: 'uppercase', letterSpacing: '.5px' }}
  >
    {children}
  </Box>
);

export const OrgSettingsNav = () => {
  const { organization } = useOrganization();
  const ability = useOrganizationAbilityContext();
  const canViewApiKeys = ability.can('read', EntityNames.ApiKey);
  const canViewMembers = ability.can('read', EntityNames.Member);

  return (
    <Box
      width={{ base: '100', tablet: '7xl' }}
      padding="xl"
      borderColor="subtle"
      borderWidth={{ base: '0 0 sm 0', tablet: '0 sm 0 0' }}
      gap="3xl"
      fontSize="sm"
      overflow={{ tablet: 'auto' }}
      className="scroll-bar-thin"
    >
      <Box gap="2xs">
        <NavSectionHeader>Organization</NavSectionHeader>
        <NavLink end className="navlink" to={`/${organization?.id}/settings`}>
          <MainNavItem>Settings</MainNavItem>
        </NavLink>
        <NavLink className="navlink" to={`/${organization?.id}/settings/domains`}>
          <MainNavItem>Domains</MainNavItem>
        </NavLink>
        {canViewApiKeys && (
          <NavLink className="navlink" to={`/${organization?.id}/settings/api-keys`}>
            <MainNavItem>API Keys</MainNavItem>
          </NavLink>
        )}
        {canViewMembers && (
          <NavLink className="navlink" to={`/${organization?.id}/settings/members`}>
            <MainNavItem>Members</MainNavItem>
          </NavLink>
        )}
        <NavLink className="navlink" to={`/${organization?.id}/settings/billing`}>
          <MainNavItem>Billing</MainNavItem>
        </NavLink>
      </Box>
    </Box>
  );
};

export default OrgSettingsNav;
