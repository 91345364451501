import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Icon,
  Popover,
  Spinner,
  toast,
  useBreakpoint,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import {
  useDeleteCodeMutation,
  useGetCodeQuery,
  useGetCodeStatsQuery,
  useGetTagsQuery,
  useGetQrsCodeQuery,
  useDeleteQrCodeMutation,
} from '../../services/zelda/codes';
import PageHeader from '../../components/PageHeader';
import { TagList } from '../../components/link/TagList';
import { DateCreated } from '../../components/DateCreated';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { CreateQRForm } from '../../components/link/CreateQRForm';
import { RedirectLinkModal } from '../../components/link/RedirectLinkModal';
import { LinkDetailStats } from '../../components/link/LinkDetailStats';
import { Code, CodeStats } from '../../types/zelda';
import QrCodeItem from '../../components/link/QrCodeItem';

const deleteCodeMessage = {
  default: 'Failed to remove Short link'
}
const deleteQrCodeMessage = {
  default: 'Failed to remove QR code'
}

export const ShortLinkDetail = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: any }>();
  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const { data: code, error, isLoading: isCodeLoading } = useGetCodeQuery({ id, organizationId });
  const { data: codeStats, error: codeStatsError, isLoading: isCodeStatsLoading } = useGetCodeStatsQuery({id, organizationId});
  const { data: orgTags } = useGetTagsQuery(organizationId);
  const { data: qrCodes, error: qrCodesError, isLoading: isQrCodesLoading } = useGetQrsCodeQuery({codeId: id, organizationId});
  const [deleteShortLink, { isLoading: isDeleting, error: deleteCodeError }] = useDeleteCodeMutation();
  const [deleteQrCode, { isLoading: isDeletingQrCode, error: deleteQrCodeError }] = useDeleteQrCodeMutation();
  const [codeData, setCodeData] = useState<Code | undefined>(undefined);
  const [codeStatsData, setCodeStatsData] = useState<CodeStats>();
  const [referralsData, setReferralsData] = useState<{ name: string; total: number }[]>([]);
  const [shortLink, setShortLink] = useState<string>('');
  const [showQrCodeForm, setShowQrCodeForm] = useState(false);

  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();
  const { isOpen: isRedirectOpen, handleToggle: toggleRedirect, handleClose: closeRedirect } = useOpenClose();
  const { isOpen: isDeleteOpen, handleOpen: openDelete, handleClose: closeDelete } = useOpenClose();

  const { isPhone } = useBreakpoint();

  useEffect(() => {
    if (!isCodeLoading && !error && code) {
      setCodeData(code);
      setShortLink(`${code?.domain}/${code?.code}`);
    }
  }, [code, error, isCodeLoading]);

  useEffect(() => {
    if (!isCodeStatsLoading && !codeStatsError && codeStats) {
      setCodeStatsData(codeStats);
      if (codeStats?.referrals) {
        const transformedReferrals = codeStats.referrals.map((referral) => ({
          name: referral.url,
          total: referral.total,
        }));
        setReferralsData(transformedReferrals);
      }
    }
  }, [codeStats, codeStatsError, isCodeStatsLoading]);

  const handleRedirectBtn = () => {
    closePopover();
    toggleRedirect();
  };

  const handleCopyUrl = () => {
    toast.success('Copied to clipboard');
    navigator.clipboard.writeText(shortLink);
  };

  const handleDelete = useCallback(async () => {
    const result = await deleteShortLink({ codeId: codeData?.id ?? '', organizationId: organization?.id });
    if (!result.error) {
      toast.success('Short link deleted');
      navigate(`/${organization?.id}/link`);
      closeDelete();
    }
  }, [closeDelete, codeData?.id, deleteShortLink, navigate, organization?.id]);

  const handleQrCodeDelete = useCallback(
    async (qrId: string) => {
      const result = await deleteQrCode({ codeId: codeData?.id ?? '', qrId, organizationId: organization?.id });
      if (!result.error) {
        toast.success('QR code deleted');
      }
    },
    [codeData?.id, deleteQrCode, organization?.id],
  );

  const popoverContent = (
    <>
      <Button variant="secondary" size="sm" onClick={handleRedirectBtn}>
        Redirect
      </Button>
      <Button
        variant="danger"
        size="sm"
        onClick={() => {
          closePopover();
          closeRedirect();
          openDelete();
        }}
      >
        Delete
      </Button>
    </>
  );

  return isCodeLoading && isCodeStatsLoading && !error && !codeStatsError && isQrCodesLoading && !qrCodesError ? (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Spinner size="xl" />
    </Box>
  ) : (
    <ScrollableMainLayout>
      <Helmet title={`${codeData?.title} Short Link`} />
      <RedirectLinkModal
        codeData={codeData}
        closeRedirect={closeRedirect}
        isRedirectOpen={isRedirectOpen}
        toggleRedirect={toggleRedirect}
        shortLink={shortLink}
      />
      <ConfirmModal
        message={`Are you sure you want to delete ${codeData?.title}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteOpen}
        onClose={closeDelete}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${codeData?.title}`}
        description="Once deleted, it will be gone forever. Please be certain."
        error={deleteCodeError}
        customErrorMessages={deleteCodeMessage}
      />
      <Box gap="lg" alignItems="flex-start">
        <Box
          width="100"
          direction={{ base: 'column', tablet: 'row' }}
          gap={{ base: '2xl', tablet: '5xl' }}
          alignItems={{ tablet: 'center' }}
        >
          <PageHeader
            flex="auto"
            eyebrow={
              <Link to={`/${organization?.id}/link`}>
                <Box alignItems="center" direction="row" gap="xs">
                  <Icon name="caret-sm-left" /> Links
                </Box>
              </Link>
            }
            title={codeData?.title}
            description={
              <Box gap="sm">
                <Box fontWeight="semibold">
                  <Link to={`https://${shortLink}`} target="_blank" rel="noopener noreferrer">
                    {shortLink}
                  </Link>
                </Box>
                <Box>
                  {codeData?.long_url && (
                    <Link
                      className="font-color-secondary"
                      to={codeData?.long_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {codeData?.long_url}
                    </Link>
                  )}
                </Box>
              </Box>
            }
          />
          <Box gap="md" direction="row" alignItems="center">
            <Button variant="primary" iconPrefix="copy-document" onClick={handleCopyUrl} fullWidth={isPhone}>
              Copy short link
            </Button>
            <Popover
              content={popoverContent}
              isOpen={isPopoverOpen}
              hasArrow={false}
              offsetFromTarget={3}
              placement="bottom-end"
              contentContainerProps={{
                padding: 'sm',
                gap: 'xs',
                borderWidth: 'sm',
                borderColor: 'default',
              }}
              onClickOutside={closePopover}
            >
              <Button variant="secondary" iconPrefix="dots" aria-label="more actions" onClick={togglePopover} />
            </Popover>
          </Box>
        </Box>

        {codeData?.id && (
          <TagList codeId={codeData.id} tags={codeData.tags ?? []} orgTags={orgTags ?? []} badgeSize="md" />
        )}
        {codeData?.createdAt && <DateCreated dateCreated={codeData.createdAt} />}
      </Box>

      <Card>
        <Card.Section>
          <div>
            {!showQrCodeForm && (
              <Button variant="secondary" onClick={() => setShowQrCodeForm(true)}>
                Create QR code
              </Button>
            )}
          </div>
          {showQrCodeForm && codeData?.id && <CreateQRForm showQr={setShowQrCodeForm} codeId={codeData.id} />}
        </Card.Section>
        {qrCodes && qrCodes.data.length > 0 && (
          <Card.Section title="QR Code">
            <Box direction="row" wrap gap="xl">
              {qrCodes.data.map((qrCode) => (
                <QrCodeItem
                  key={qrCode.id}
                  qrCode={qrCode}
                  isDeleting={isDeletingQrCode}
                  handleQrCodeDelete={handleQrCodeDelete}
                  error={deleteQrCodeError}
                  deleteQrCodeMessage={deleteQrCodeMessage}
                />
              ))}
            </Box>
          </Card.Section>
        )}
      </Card>

      <LinkDetailStats referralsData={referralsData} codeStatsData={codeStatsData} />
    </ScrollableMainLayout>
  );
};

export default ShortLinkDetail;
