import { Button, FormikTextInput, Modal, toast } from "@hyphen/hyphen-components";
import { Formik, Field, Form } from "formik";
import { ApiError } from "../ApiError";
import { useCallback } from "react";
import * as yup from 'yup';
import { InferType } from "yup";
import { useCreateMemberMutation } from "../../services/member";
import { ModalProps } from '../types/modal';

const inviteMemberSchema = yup.object().shape({
  firstName: yup.string().required('Name is required'),
  lastName: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
})

type InviteMemberSchema = InferType<typeof inviteMemberSchema>;

const errorMessages = {
  default: 'Failed to invite member',
}

export const InviteMemberModal = ({ isOpen, onClose, data }: ModalProps) => {
  const [createMember, { error }] = useCreateMemberMutation();

  const handleOnSubmit = useCallback(async (values: InviteMemberSchema) => {
    console.log("HERE!!!")
    const { error, data: responseData } = await createMember({ firstName: values.firstName, lastName: values.lastName, email: values.email, organizationId: data.organization.id });

    if (!error && responseData) {
      toast.success('Member Invited', { duration: 5000 });
      onClose();
    }
  }, [createMember, data.organization.id, onClose]);

  return (
    <Modal ariaLabelledBy="inviteMemberModal" isOpen={isOpen} onDismiss={onClose} maxWidth="9xl">
      <Modal.Header id="inviteMemberModal" title="Invite Member" onDismiss={onClose} />
      <Formik
        initialValues={{ firstName: '', lastName: '', email: '' }}
        validationSchema={inviteMemberSchema}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            <Modal.Body padding="4xl" gap="xl">
              <Field
                id="firstName"
                name="firstName"
                label="First Name"
                component={FormikTextInput}
                error={errors.firstName}
                isRequired
              />
              <Field
                id="lastName"
                name="lastName"
                label="Last Name"
                component={FormikTextInput}
                error={errors.lastName}
                isRequired
              />
              <Field
                id="email"
                name="email"
                label="Email"
                component={FormikTextInput}
                error={errors.email}
                isRequired
              />
              {error ? <ApiError error={error} customMessages={errorMessages} /> : null}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" isLoading={isSubmitting}>
                Invite
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
};