import { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Box,
  CheckboxInput,
  Icon,
  Popover,
  TextInput,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { useUpdateCodeMutation } from '../../services/zelda/codes';
import { UpdateCodeRequestBody } from '../../types/zelda';

type TagListProps = {
  codeId: string;
  tags: string[];
  orgTags: string[];
  badgeSize?: 'sm' | 'md';
};

export const TagList = ({ codeId, tags, orgTags, badgeSize = 'sm' }: TagListProps) => {
  const [updateShortLink, { isLoading: isUpdating }] = useUpdateCodeMutation();

  const { isOpen: isTagPopoverOpen, handleClose: closeTagPopover, handleOpen: openTagPopover } = useOpenClose();

  const [tagList, setTagList] = useState<{ value: string; isChecked: boolean }[]>([]);
  const [newTag, setNewTag] = useState<string>('');

  useEffect(() => {
    if (orgTags.length > 0) {
      const trasformedTags = orgTags.map((tag) => ({ value: tag, isChecked: tags.includes(tag) }));
      setTagList(trasformedTags);
    }
  }, [orgTags, tags]);

  const handleOnTagsChange = useCallback(
    (tagChanged: { value: string; isChecked: boolean }) => {
      const updatedTagsData = tagList.map((tag) => {
        if (tag.value === tagChanged.value) {
          return { ...tag, isChecked: !tagChanged.isChecked };
        }
        return tag;
      });

      setTagList(updatedTagsData);
    },
    [tagList],
  );

  const handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag(event.target.value);
  };

  const handleSave = useCallback(async () => {
    const flatSelectedTags = tagList.filter((item) => item.isChecked).map((item) => item.value);
    try {
      const trimmedNewTag = newTag.trim();

      const tagsToUpdate =
        trimmedNewTag && !flatSelectedTags.includes(trimmedNewTag)
          ? [...flatSelectedTags, trimmedNewTag]
          : flatSelectedTags;

      if (JSON.stringify(tagsToUpdate) === JSON.stringify(tags)) {
        setNewTag('');
        closeTagPopover();
        toast.success('No changes to save');
        return;
      }

      const body: UpdateCodeRequestBody = {
        tags: tagsToUpdate,
      };

      const { error, data } = await updateShortLink({ codeId, updateData: body });

      if (!error && data) {
        setNewTag('');
        toast.success('Tags saved');
        closeTagPopover();
      } else if (error) {
        toast.success((error as any).data.error);
      }
    } catch (e) {
      toast.success('Unknown error');
    }
  }, [closeTagPopover, codeId, newTag, tagList, tags, updateShortLink]);

  const tagPopoverContent = (
    <Box gap="lg">
      <TextInput
        id="addTagInput"
        label="Enter new tag"
        size="sm"
        hideLabel
        placeholder="Add new tag"
        value={newTag}
        onChange={handleNewTagChange}
      />
      {tagList.length > 0 && (
        <Box gap="sm" overflow="auto" className="scroll-bar-thin" maxHeight="7xl">
          {tagList.map((tag) => (
            <CheckboxInput
              id={`tag-${tag.value}`}
              label={tag.value}
              value={tag.value}
              key={tag.value}
              size="sm"
              isChecked={tag.isChecked}
              onChange={() => handleOnTagsChange(tag)}
            />
          ))}
        </Box>
      )}
      <Button variant="primary" size="sm" onClick={handleSave} isLoading={isUpdating}>
        Save
      </Button>
    </Box>
  );

  return (
    <Box direction="row" gap="sm" alignItems="center">
      <Icon name="tag" size={badgeSize} color="placeholder" />

      {tags.length > 0 && (
        <Box wrap direction="row" gap="xs">
          {tags.map((tag) => (
            <Badge key={tag} variant="light-grey" message={tag} size={badgeSize} />
          ))}
        </Box>
      )}
      <Popover
        content={tagPopoverContent}
        isOpen={isTagPopoverOpen}
        placement="bottom-start"
        offsetFromTarget={4}
        onClickOutside={closeTagPopover}
        hasArrow={false}
        contentContainerProps={{
          padding: 'sm',
          gap: 'xs',
          borderWidth: 'sm',
          borderColor: 'default',
          maxWidth: '7xl',
        }}
      >
        <Button variant="tertiary" size={badgeSize} onClick={openTagPopover}>
          add tag
        </Button>
      </Popover>
    </Box>
  );
};
