import { Button, FormikTextInput, Modal, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import { useCallback } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Code, UpdateCodeRequestBody } from '../../types/zelda';
import { useUpdateCodeMutation } from '../../services/zelda/codes';
import { ApiError } from '../ApiError';

const newLongUrlSchema = (shortDomain: string) => {
  return yup.object().shape({
    newLongUrl: yup
      .string()
      .required('Required')
      .url('Invalid URL, must be a valid URL including http:// or https://')
      .test('no-short-domain', 'Destination cannot be the same as the domain', (value) => {
        return !value.includes(shortDomain);
      }),
  });
};

type SchemaReturnType<T extends (...args: any[]) => any> = ReturnType<T> extends infer U
  ? U extends object
    ? // @ts-ignore
      InferType<U>
    : never
  : never;

type newLongUrlFormSchema = SchemaReturnType<typeof newLongUrlSchema>;

interface RedirectLinkModalProps {
  isRedirectOpen: boolean;
  closeRedirect: () => void;
  toggleRedirect: () => void;
  shortLink: string;
  codeData?: Code;
}

export const RedirectLinkModal = ({
  codeData,
  closeRedirect,
  isRedirectOpen,
  toggleRedirect,
  shortLink,
}: RedirectLinkModalProps) => {
  const [updateShortLink, { error }] = useUpdateCodeMutation();

  const handleApplyRedirect = useCallback(
    async (values: newLongUrlFormSchema) => {
      if (!codeData) {
        return;
      }

      const body: UpdateCodeRequestBody = {
        long_url: values.newLongUrl,
      };

      const { error, data } = await updateShortLink({ codeId: codeData.id, updateData: body });
      if (!error && data) {
        toast.success('Redirect applied');
        toggleRedirect();
      }
    },
    [codeData, toggleRedirect, updateShortLink],
  );

  return (
    <Modal ariaLabelledBy="redirectModal" isOpen={isRedirectOpen} onDismiss={closeRedirect} maxWidth="9xl">
      <Modal.Header id="redirectModal" title="Redirect Link" onDismiss={closeRedirect} />
      <Formik
        initialValues={{ newLongUrl: '' }}
        validationSchema={newLongUrlSchema(codeData?.domain ?? '')}
        onSubmit={handleApplyRedirect}
      >
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            <Modal.Body padding="4xl" gap="xl">
              <p>
                You are changing the destination for <strong>{shortLink}</strong>.
              </p>
              <Field
                type="text"
                label="New Destination URL"
                name="newLongUrl"
                id="newLongUrl"
                component={FormikTextInput}
                placeholder="https://example.com"
                error={errors.newLongUrl}
                isRequired
              />
              {error ? <ApiError error={error} /> : null}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeRedirect} isDisabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" isLoading={isSubmitting}>
                Apply Redirect
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
