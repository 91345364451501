import { Box } from '@hyphen/hyphen-components';
import { CodeBlock } from '../Codeblock';

export const DisplaySecretKey = ({ secret }: { secret: string }) => (
  <Box gap="2xl">
    <Box fontWeight="semibold" fontSize="xl">
      Save Your Secret Key
    </Box>
    <p>
      Please save this secret key somewhere safe.{' '}
      <strong>For security reasons, you won't be able to view it again.</strong>
    </p>
    <p>If you lose this secret, you'll need to generate a new one.</p>
    <CodeBlock showCopyLabel>{secret}</CodeBlock>
  </Box>
);
