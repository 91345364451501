import { Helmet } from 'react-helmet';
import { Box, Button, Icon, Spinner } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetDomainsQuery } from '../../services/zelda/domains';
import CreateLinkForm from '../../components/link/CreateLinkForm';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';

export default function CreateLink() {
  const { organization, isLoading: organizationLoading } = useOrganization();
  const organizationId = organization?.id;
  const { data: domains, isLoading } = useGetDomainsQuery({ verified: true, organizationId});

  const hasVerifiedDomains = domains?.data && domains.data?.length > 0;

  const renderPageHeader = () => {
    if(domains?.data) {
      return (
        <PageHeader
          eyebrow={
            <Link to={`/${organization?.id}/link`}>
              <Box alignItems="center" direction="row" gap="xs">
                <Icon name="caret-sm-left" /> Link Dashboard
              </Box>
            </Link>
          }
          title={hasVerifiedDomains ? 'Create Short Link' : 'No domains found'}
          description={hasVerifiedDomains ? 'Create a branded short link that you can share, manage and analyze' : 'You need to create a domain before you can create a link'}
        />
      );
    }
  }

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Create Short Link</title>
      </Helmet>

      {isLoading || organizationLoading ? (
        <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
          <Spinner size="md" />
          Loading...
        </Box>
      ) : (
        <>
          {renderPageHeader()}
          {hasVerifiedDomains ? (
            <CreateLinkForm />
          ) : (
            <Box width="100" gap="md" alignItems="flex-start">
              <Link to={`/${organization?.id}/settings/domains/add`}>
                <Button variant="primary" size="md">
                  Add a Domain
                </Button>
              </Link>
            </Box>
          )}
        </>
      )}
    </ScrollableMainLayout>
  )
}