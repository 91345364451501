import React, { useEffect, useState } from 'react';
import { Spinner, Box, Heading, Button } from '@hyphen/hyphen-components';
import { Env, useGetAppEnvVersionsQuery } from '../../services/env';
import EnvVersionsTable from './EnvVersionsTable';
import Error from '../../Error';

const PAGE_SIZE = 10;

function EnvironmentAppEnvVersionsList({ orgId, appId }: { orgId: string; appId: string }) {
  const [pageNum, setPageNumber] = useState<number>(1);
  const [versionsContent, setVersionsContent] = useState<Env[]>([]);

  const {
    data: versions,
    isLoading,
    error,
  } = useGetAppEnvVersionsQuery({
    organizationId: orgId,
    appId: appId,
    pageSize: PAGE_SIZE,
    pageNum,
  });

  const handleLoadMore = () => {
    if (versions?.total && versionsContent.length < versions.total) {
      setPageNumber((prevPageNum) => prevPageNum + 1);
    }
  };

  useEffect(() => {
    if (versions?.data) {
      setVersionsContent((prevVersions) => {
        const newVersions = versions.data.filter(
          (newVersion) => !prevVersions.some((prevVersion) => prevVersion.id === newVersion.id),
        );

        if (pageNum === 1) {
          return versions.data;
        } else {
          return [...prevVersions, ...newVersions];
        }
      });
    }
  }, [versions?.data, pageNum]);

  if (error && !isLoading) {
    return <Error />;
  }

  if (isLoading && pageNum === 1) {
    return <Spinner />;
  }

  return (
    <Box gap="lg">
      <Heading as="h2" size="sm">
        ENV Version History
      </Heading>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <EnvVersionsTable versionsContent={versionsContent} />
          {versions?.total && versions.total > versionsContent.length ? (
            <Box alignItems="flex-start">
              <Button onClick={handleLoadMore} size="sm" variant="secondary">
                Load more
              </Button>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
}

export default EnvironmentAppEnvVersionsList;
