import { DateTime } from 'luxon';

export const formatDateWithTime = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  return date.toFormat('MM/dd/yyyy hh:mm a');
};

export const formatDateInUTC = (dateString: string) => {
  const date = DateTime.fromISO(dateString, { zone: 'utc' });
  return date.toFormat('MM/dd/yyyy');
};

export const timeAgo = (date: string) => {
  const dateTime = DateTime.fromISO(date);
  const now = DateTime.now();
  return dateTime.toRelative({ base: now }) || undefined;
};

