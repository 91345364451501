import { Project } from '../../../services/projects';
import { Helmet } from 'react-helmet';
import ProjectAccessList from '../../../components/projects/ProjectAccessList';

export default function ProjectAccess({ project }: { project: Project }) {
  return (
    <>
      <Helmet title={`${project?.name} Access`} />
      <ProjectAccessList project={project} />
    </>
  )
}
