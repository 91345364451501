import { Table } from '@hyphen/hyphen-components';
import { Env } from '../../services/env';
import { formatDateWithTime } from '../../utils/dateUtils';

interface EnvVersionsTableProps {
  versionsContent: Env[];
}

export default function EnvVersionsTable({ versionsContent }: EnvVersionsTableProps) {
  const columnConfig = [
    {
      heading: 'Version',
      dataKey: 'version',
    },
    {
      heading: 'Secrets',
      dataKey: 'countVariables',
    },
    {
      heading: 'Size',
      dataKey: 'size',
    },
    {
      heading: 'Published',
      dataKey: 'published',
    },
  ];

  if (versionsContent.length === 0) return <>No ENV versions exist</>;

  const tableData = versionsContent.map((v) => ({
    version: v.version,
    size: v.size,
    countVariables: v.countVariables,
    published: formatDateWithTime(v.published),
    key: v.id,
  }));

  return <Table rowKey="key" columns={columnConfig} rows={tableData} />;
}
