import { Modal, Spinner } from '@hyphen/hyphen-components';

import { useGetTeamQuery } from '../../services/teams';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';

export default function AddMembersModal() {
  const { organization = {} as Organization } = useOrganization();
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const closeModal = () => {
    navigate('..');
  };

  const { isLoading, data } = useGetTeamQuery({ orgId: organization.id, id });

  return (
    <Modal ariaLabelledBy="addMembersHeader" isOpen onDismiss={closeModal} maxWidth="10xl">
      <Modal.Header id="addMembersHeader" title={`Add Members to ${data?.name}`} onDismiss={closeModal} />
      {isLoading ? <Spinner /> : <>TODO: Form coming soon</>}
    </Modal>
  );
}
