import { Spinner } from '@hyphen/hyphen-components';
import { useGetTeamQuery } from '../../services/teams';
import { useParams } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import EditTeamForm from '../../components/teams/EditTeamForm';
import DeleteTeam from '../../components/teams/DeleteTeam';

export default function TeamSettings() {
  const { organization = {} as Organization } = useOrganization();
  const { id } = useParams<{ id: any }>();

  const { isLoading, data } = useGetTeamQuery({ orgId: organization.id, id });

  return (
    <ScrollableMainLayout>
      <Helmet title={`${data?.name || 'Team'} Settings`} />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <PageHeader
            eyebrow={<Link to={`/${organization?.id}/teams/${data?.id}`}>{data?.name}</Link>}
            title={`${data?.name} Settings`}
          />
          <EditTeamForm data={data} organizationId={organization.id} />
          <DeleteTeam team={data} organizationId={organization.id} />
        </>
      )}
    </ScrollableMainLayout>
  );
}
