import { Box, Button, Card, Heading, Icon, Spinner } from '@hyphen/hyphen-components';
import { useGetEnvsQuery } from '../../services/env';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import EnvironmentItem from '../common/EnvironmentItem';
import { App } from '../../services/apps';
import { useGetProjectEnvironmentsQuery } from '../../services/environments';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { subject } from '@casl/ability';
import { EntityNames } from '../../types/executionContext';

export const EnvsList = ({ app }: { app: App }) => {
  const { organization = {} as Organization } = useOrganization();

  const ability = useOrganizationAbilityContext();
  const canReadEnv = ability.can('read', subject(EntityNames.Env, { project: { id: app.project.id } }));

  const secretsList = canReadEnv ? (
    <EnvironmentsWithSecrets app={app} organizationId={organization.id} />
  ) : (
    <Environments app={app} organizationId={organization.id} />
  );

  return <Box gap="xl">{secretsList}</Box>;
};

const Environments = ({ app, organizationId }: { app: App; organizationId: string }) => {
  const { data, isLoading } = useGetProjectEnvironmentsQuery({
    organizationId,
    projectId: app.project.id,
  });

  const projectEnvironments = data?.data ?? [];

  return (
    <>
      <Heading as="h2" size="sm">
        Environments
      </Heading>
      {isLoading ? (
        <Box height="5xl">
          <Spinner />
        </Box>
      ) : projectEnvironments.length > 0 ? (
        <Box
          width="100"
          direction="row"
          gap="lg"
          overflow="auto"
          display="grid"
          fontSize="xs"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
          }}
        >
          {projectEnvironments.map((env) => (
            <EnvironmentItem key={env.id} {...env} app={app} hideSecrets />
          ))}
        </Box>
      ) : (
        <Box padding="3xl" textAlign="center">
          No environments found
        </Box>
      )}
    </>
  );
};

const EnvironmentsWithSecrets = ({ app, organizationId }: { app: App; organizationId: string }) => {
  const { data: envs, isLoading } = useGetEnvsQuery({ organizationId, appId: app.id });
  const secretsData = envs?.data ?? [];

  const { data: projectEnvironments, isLoading: projectEnvironmentsIsLoading } = useGetProjectEnvironmentsQuery({
    organizationId,
    projectId: app.project.id,
  });

  const projectEnvironmentsWithSecrets =
    projectEnvironments?.data?.map((env) => ({
      ...env,
      secrets: secretsData.find((secret) => secret.projectEnvironment?.id === env.id),
    })) ?? [];

  const defaultSecrets =
    (secretsData.length ?? 0) > 0
      ? {
          name: 'Default (all)',
          secrets: secretsData.find((secret) => !secret.projectEnvironment),
        }
      : undefined;

  if (isLoading || projectEnvironmentsIsLoading)
    return (
      <Box height="5xl">
        <Spinner />
      </Box>
    );

  return (
    <>
      {secretsData.length === 0 && (
        <Card background="brand-gradient-purple-reverse" color="white" margin="0 0 xl 0">
          <Card.Section>
            <Box
              gap="lg"
              direction={{ base: 'column', tablet: 'row' }}
              alignItems={{ base: 'flex-start', tablet: 'center' }}
            >
              <Box>
                <Icon name="c-info" size="lg" />
              </Box>
              <Box flex="auto" fontSize="sm" gap="xs">
                <Box has="h2" fontWeight="bold">
                  Initialize App for Local Development
                </Box>
                <Box>Establish an encryption key and keep app secrets in-sync with your team</Box>
              </Box>
              <Button
                as="a"
                target="_blank"
                href="https://docs.hyphen.ai/docs/env-secrets-management"
                variant="primary"
                iconSuffix="arrow-right"
                style={{ whiteSpace: 'nowrap' }}
              >
                ENV Setup Guide
              </Button>
            </Box>
          </Card.Section>
        </Card>
      )}
      <Heading as="h2" size="sm">
        Environments
      </Heading>
      {projectEnvironmentsWithSecrets.length > 0 ? (
        <Box
          width="100"
          direction="row"
          gap="lg"
          overflow="auto"
          display="grid"
          fontSize="xs"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
          }}
        >
          {defaultSecrets && <EnvironmentItem {...defaultSecrets} app={app} />}
          {projectEnvironmentsWithSecrets.map((env) => (
            <EnvironmentItem key={env.id} {...env} app={app} />
          ))}
        </Box>
      ) : (
        <Box padding="3xl" textAlign="center">
          No environments found
        </Box>
      )}
    </>
  );
};
