import { Box, Heading, Pagination, Spinner, Table } from "@hyphen/hyphen-components";
import { Project, useGetAccessQuery } from "../../services/projects";
//import { useOrganizationAbilityContext } from "../auth/OrganizationAbilityProvider";
import useQueryStringState from "../../hooks/useQueryStringState";
import { useCallback } from "react";
import { RoleDisplayName } from "../../utils/roles";

export default function ProjectAccessList({ project }: { project: Project }) {
  // const ability = useOrganizationAbilityContext();
  // const canUpdateProject = ability.can('update', project);
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const { data: access, isLoading } = useGetAccessQuery({
    projectId: project.id,
    organizationId: project.organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize
  });

  const handlePageNumberChange = useCallback((pageNum: number) => {
    setQsState({ pageNum, pageSize: qsState.pageSize });
  }, [qsState.pageSize, setQsState]);

  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'assignment',
      render: (assignment: any) => assignment.name
    },
    {
      heading: "Roles",
      dataKey: "roles",
      render: (roles: any) => (roles || []).map(RoleDisplayName).join(',')
    }
  ]


  return (
    <>
      <Box alignItems="flex-start" gap="xl">
        <Heading as="h2" size="sm">
          Access
        </Heading>
        {/* {canUpdateProject && (
          <Button
            variant="primary"
            size="sm"
            iconPrefix="add"
          >
            Give Access
          </Button>
        )} */}
        {isLoading ? <Box><Spinner /></Box> : (
          <Box width="100" gap="lg">
            <Table
              rowKey="id"
              isLoading={isLoading}
              columns={columnConfig}
              rows={access?.data || []}
            />
            {
              access?.total && access?.total > qsState.pageSize ? (
                <Pagination
                  activePage={qsState.pageNum}
                  itemsPerPage={qsState.pageSize}
                  onChange={handlePageNumberChange}
                  totalItemsCount={access?.total ?? 0}
                  numberOfPagesDisplayed={3}
                  isCompact
                  arePagesVisible
                />
              ) : null
            }
          </Box>
        )}
      </Box>
    </>);
}