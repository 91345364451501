import { Box, Card } from '@hyphen/hyphen-components';

export default function DynamicTeamCriteria({ criteria }: { criteria: any[] }) {
  const content = criteria.map((criteria, index: number) => (
    <Box key={index} fontSize="sm" direction="row" gap="xl" alignItems="center">
      {index > 0 && (
        <Box width="4xl" padding="0 lg" fontSize="sm" fontFamily="monospace" color="secondary">
          AND
        </Box>
      )}
      <Box
        padding={index === 0 && criteria!.length > 1 ? 'md md md 5xl' : 'md xl'}
        direction={{ base: 'column', tablet: 'row' }}
        flex="auto"
        gap="lg"
        background="secondary"
        radius="md"
      >
        <Box minWidth="6xl" width="6xl">
          {criteria.type === 'property' ? criteria.field : criteria.type}
        </Box>
        <Box width="6xl" minWidth="6xl" fontSize="sm" fontFamily="monospace" style={{ textTransform: 'uppercase' }}>
          {criteria.type === 'property'
            ? criteria.operator
            : criteria.conjunction === 'and'
            ? 'includes'
            : 'is either'}
        </Box>
        <Box flex="auto">{criteria.type === 'property' ? criteria.value : criteria.values.join(', ')}</Box>
      </Box>
    </Box>
  ));

  return (
    <Card>
      <Card.Section gap="xs">
        <Box as="h4" fontSize={{ base: 'sm', tablet: 'md' }} fontWeight="medium">
          Dynamic Team Membership Criteria
        </Box>
        <Box as="p" color="secondary" fontSize={{ base: 'xs', tablet: 'sm' }}>
          Team membership automatically organized based specified criteria
        </Box>
      </Card.Section>
      <Card.Section gap="md">{content}</Card.Section>
    </Card>
  );
}
