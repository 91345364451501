export const cleanRequest = (body: Record<string, any>) => {
  for (const key in body) {
    if (typeof body[key] === 'string') {
      if (body[key].trim().length === 0) {
        delete body[key];
      } else {
        body[key] = body[key].trim();
      }
    }
  }
  return body;
};
